import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  expertPagination: {},
};

const expterSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {
    setExpertPagination: (state, actions) => {
      state.expertPagination = actions.payload;
    },
  },
});

export const { setExpertPagination } = expterSlice.actions;

export default expterSlice.reducer;
