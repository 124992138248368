import api from "service/api";

export function isAdminExists() {
  return api.get("/api/auth/check-admin");
}

export function signUp(data) {
  return api.post("/api/auth/admin/sign-up", data);
}

export function login(data) {
  return api.post("/api/auth/admin/login", data);
}

export function forgotPassword(data) {
  return api.post(`/api/auth/admin/forgot-password`, data);
}

export function resetPassword(data) {
  return api.post(`/api/auth/admin/reset-password`, data);
}
