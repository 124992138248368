import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  endUsersPagination: {},
};

export const endUsersSlice = createSlice({
  name: "endUser",
  initialState,
  reducers: {
    setEndUsersPagination: (state, action) => {
      state.endUsersPagination = action.payload;
    },
  },
});

export const { setEndUsersPagination } = endUsersSlice.actions;

export default endUsersSlice.reducer;
