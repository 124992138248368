import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import industry from "./industry";
import tabStatus from "./tabs/tabsSlice";
import endUser from "./endUsers";
import experts from "./experts";
import configurations from "./config";
import problem from "./problem";
import support from "./support";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    industry,
    tabStatus,
    endUser,
    experts,
    configurations,
    problem,
    support,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
