const { createSlice } = require("@reduxjs/toolkit");

export const initialState = {
  industryPagination: {},
};

export const industrySlice = createSlice({
  name: "industry",
  initialState,
  reducers: {
    setIndustryPagination: (state, action) => {
      state.industryPagination = action.payload;
    },
  },
});

export const { setIndustryPagination } = industrySlice.actions;

export default industrySlice.reducer;
