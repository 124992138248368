const { createSlice } = require("@reduxjs/toolkit");

export const initialState = {
  problemNewPlantPagination: {},
  problemTroubleshootingPagination: {},
};

export const problemSlice = createSlice({
  name: "problem",
  initialState,
  reducers: {
    setProblemNewPlantPagination: (state, action) => {
      state.problemNewPlantPagination = action.payload;
    },
    setProblemTroubleshootingPagination: (state, action) => {
      state.problemTroubleshootingPagination = action.payload;
    },
  },
});

export const {
  setProblemNewPlantPagination,
  setProblemTroubleshootingPagination,
} = problemSlice.actions;

export default problemSlice.reducer;
